<template>
  <v-container>
    <DialogFullScreen
      title="Registro de actividad"
      :visible="visible"
      @closeDialog="$emit('closeDialog')"
    >
      <v-container v-if="activity">
        <v-row class="mt-2">
          <v-col cols="12">
            <h3
              :class="'subtitle-2 ' + (darkTheme ? 'text-white' : 'text-black')"
            >
              {{ opportunity.Opportunitie.Name }}
            </h3>
            <span
              :class="'subtitle-1 ' + (darkTheme ? 'text-white' : 'text-black')"
              >{{ opportunity.Cliente.Name }}</span
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" v-if="activityTypes">
            <v-select
              v-model="activity.ActivityTypeId"
              :items="activityTypes"
              prepend-icon="mdi-timelapse"
              label="Selecciona un tipo de actividad"
              item-text="Name"
              item-value="Id"
              ref="activityType"
              :rules="[
                () => !!activity.ActivityTypeId || 'Este campo es requerido'
              ]"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="activity.Objective"
              prepend-icon="mdi-domain"
              label="Objetivo"
              ref="objective"
              :rules="[() => !!activity.Objective || 'Este campo es requerido']"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-menu
              v-model="menuDateActivity"
              :close-on-content-click="false"
              transition="scale-transition"
              nudge-bottom="10"
              offset-y
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateActivity"
                  label="Fecha"
                  prepend-icon="mdi-calendar"
                  readonly
                  ref="dateActivity"
                  :rules="[() => !!dateActivity || 'Este campo es requerido']"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                bottom
                v-model="dateActivity"
                @input="menuDateActivity = false"
                full-width
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12">
            <v-menu
              ref="menu"
              v-model="menuTimeActivity"
              :close-on-content-click="false"
              :return-value.sync="timeActivity"
              transition="scale-transition"
              nudge-bottom="10"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="timeActivity"
                  label="Hora"
                  prepend-icon="mdi-clock-outline"
                  readonly
                  ref="timeActivity"
                  :rules="[() => !!timeActivity || 'Este campo es requerido']"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menuTimeActivity"
                v-model="timeActivity"
                ampm-in-title
                format="ampm"
                full-width
                @click:minute="$refs.menu.save(timeActivity)"
              ></v-time-picker>
            </v-menu>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="activity.Participants"
              prepend-icon="mdi-account-multiple"
              label="Participantes"
              ref="participants"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="activity.Notes"
              prepend-icon="mdi-comment"
              label="Nota"
              rows="1"
              auto-grow
              ref="notes"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-switch
              v-model="activity.Status"
              label="Actividad realizada"
            ></v-switch>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-btn block color="primary" @click="sendSaveActivity">
              Guardar
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn block color="secondary" @click="$emit('closeDialog')">
              Cancelar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </DialogFullScreen>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import DialogFullScreen from '@/components/DialogFullScreen.vue'
// import ConfirmDialog from '@/components/ConfirmDialog.vue'

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    opportunity: {
      type: Object,
      required: true
    },
    activity: {
      type: Object,
      required: true
    },
    activityTypes: {
      type: Array,
      required: true
    }
  },
  components: {
    DialogFullScreen
    // ConfirmDialog
  },
  data() {
    return {
      menuDateActivity: false,
      menuTimeActivity: false,
      dateActivity: null,
      timeActivity: null,
      formHasErrors: false
    }
  },
  methods: {
    sendSaveActivity() {
      this.formHasErrors = false

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true
        this.$refs[f].validate(true)
      })

      if (!this.formHasErrors) {
        this.activity.Datetime = new Date(
          this.dateActivity + ' ' + this.timeActivity
        ).toISOString()
        this.$emit('saveActivity', this.activity)
      }
    }
  },
  computed: {
    ...mapState('account', ['darkTheme']),
    form() {
      return {
        activityType: this.activity.ActivityTypeId,
        objective: this.activity.Objective,
        dateActivity: this.dateActivity,
        timeActivity: this.timeActivity
        // participants: this.activity.Participants,
        // notes: this.activity.Notes
      }
    }
  },
  mounted() {
    if ('Id' in this.activity) {
      let date = moment(this.activity.Datetime).format('YYYY-MM-DD')
      let time = moment(this.activity.Datetime).format('HH:mm:ss')
      this.dateActivity = date
      this.timeActivity = time
    } else {
      let date = moment().format('YYYY-MM-DD')
      let time = moment().format('HH:mm:ss')
      this.dateActivity = date
      this.timeActivity = time
    }
  }
}
</script>
