<template>
  <div>
    <v-expansion-panels accordion>
      <v-expansion-panel>
        <v-expansion-panel-header>Actividades</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list
            v-if="activities && activities.length > 0"
            :class="darkTheme ? 'hasSeparatorsD' : 'hasSeparators'"
          >
            <v-list-item
              three-line
              v-for="(item, index) in activities"
              :key="index"
            >
              <v-list-item-content>
                <v-list-item-title
                  class="d-flex justify-space-between align-center"
                >
                  <div class="card-custom-title">
                    <span
                      :class="
                        'subtitle-2 ' +
                        (darkTheme ? 'text-white' : 'text-black')
                      "
                    >
                      {{ item.Activities.Objective }}
                    </span>
                  </div>
                  <div>
                    <v-icon :color="getIcon(item).color">
                      {{ getIcon(item).icon }}
                    </v-icon>
                    <v-btn icon @click="$emit('editActivity', item.Activities)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </div>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.ActivityTypes.Name }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ getDate(item.Activities.Datetime) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <span class="subtitle-2" v-else>No hay actividades</span>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Documentos</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list
            v-if="documents && documents.length > 0"
            :class="darkTheme ? 'hasSeparatorsD' : 'hasSeparators'"
          >
            <v-list-item v-for="(item, index) in documents" :key="index">
              <v-list-item-content>
                <v-list-item-title
                  :class="
                    'subtitle-2 ' + (darkTheme ? 'text-white' : 'text-black')
                  "
                >
                  {{ item.Name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-btn icon @click="showDialogDeleteDocument(item.Id)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn
                  :loading="downloadFileButtonLoading"
                  icon
                  @click="downloadDocument(true, item.Id)"
                  ><v-icon>mdi-download</v-icon></v-btn
                >
              </v-list-item-icon>
            </v-list-item>
          </v-list>
          <span class="subtitle-2" v-else>No hay documentos</span>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Riesgos y dependencias
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row v-if="opportunity">
            <v-col cols="12">
              <v-textarea
                auto-grow
                rows="1"
                label="Riesgos"
                v-model="opportunity.Opportunitie.Risks"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-textarea
                auto-grow
                rows="1"
                label="Dependencias"
                v-model="opportunity.Opportunitie.Dependencies"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-btn
                block
                color="primary"
                @click="showConfirmUpdateOpportunity = true"
                >Guardar</v-btn
              >
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <ConfirmDialog
      :show="showConfirmDeleteDocument"
      question="¿Seguro que desea eliminar este documento?"
      @cancelado="showConfirmDeleteDocument = false"
      @aceptado="deleteDocument(true, documentToDelete)"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import mime from 'mime-types'
import moment from 'moment'
import ConfirmDialog from '@/components/ConfirmDialog.vue'

export default {
  components: {
    ConfirmDialog
  },
  props: {
    activities: {
      type: Array,
      default: null
    },
    documents: {
      type: Array,
      default: null
    },
    opportunity: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showConfirmDeleteDocument: false,
      downloadFileButtonLoading: false
    }
  },
  methods: {
    getIcon(item) {
      let icon = { icon: '', color: '' }

      if (item.Activities.Status) {
        icon.icon = 'mdi-check-bold'
        icon.color = this.darkTheme ? '' : 'success'
      } else {
        let today = new Date()
        let actDate = new Date(item.Activities.Datetime)
        if (actDate >= today) {
          icon.icon = 'mdi-chevron-right-circle'
          icon.color = this.darkTheme ? '' : 'primary'
        } else {
          icon.icon = 'mdi-alert'
          icon.color = this.darkTheme ? '' : 'warning'
        }
      }
      return icon
    },
    getDate(dateString) {
      return moment(dateString).format('YYYY-MM-DD HH:mm:ss')
    },
    downloadDocument(showOverlay, documentId) {
      this.downloadFileButtonLoading = true

      let headers = {
        documentID: documentId
      }

      axios
        .get(this.$endpointsBaseUrl + 'Documents/Get_Documents', {
          showOverlay,
          headers: headers
        })
        .then((response) => {
          let type = mime.types[response.data[0].Type]

          var a = document.createElement('a')
          a.href = 'data:' + type + ';base64,' + response.data[0].File
          a.download = response.data[0].Name
          a.click()
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
        .then(() => {
          this.downloadFileButtonLoading = false
        })
    },
    deleteDocument(showOverlay, documentId) {
      axios
        .delete(
          this.$endpointsBaseUrl + 'Documents/Delete_Document',
          { data: documentId },
          {
            showOverlay
          }
        )
        .then(() => {
          this.$store.commit('showSnackBar', {
            text: 'Documento eliminado',
            color: 'success',
            icon: 'mdi-check-bold'
          })
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
        .then(() => {
          this.showConfirmDeleteDocument = false
          this.$emit('updateDocuments')
        })
    },
    showDialogDeleteDocument(documentId) {
      this.documentToDelete = documentId
      this.showConfirmDeleteDocument = true
    }
  },
  computed: {
    ...mapState('account', ['darkTheme'])
  }
}
</script>
