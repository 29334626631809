<template>
  <DialogFullScreen
    title="Detalles"
    :visible="visible"
    @closeDialog="$emit('closeDialog')"
  >
    <v-list :class="darkTheme ? 'hasSeparatorsD' : 'hasSeparators'">
      <v-list-item v-if="opportunity">
        <v-list-item-icon>
          <v-icon>mdi-trophy-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle>
            {{ 'Mustwin' in opportunity.Opportunitie ? 'Es un' : 'No es un' }}
            Must Win
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ opportunity.CompanyType.Name }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="opportunity && 'TakeDecision' in opportunity.Opportunitie"
      >
        <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle>
            Acceso a tomador de decisión
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ opportunity.Opportunitie.TakeDecision }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="details && 'BusinessUnities' in details">
        <v-list-item-icon><v-icon>mdi-domain</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle> Unidad de negocio </v-list-item-subtitle>
          <v-list-item-subtitle>
            <v-icon>mdi-circle-small</v-icon>
            {{ details.BusinessUnities[0].Name }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="details && 'Solutions' in details">
        <v-list-item-icon><v-icon>mdi-brain</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle> Solución </v-list-item-subtitle>
          <v-list-item-subtitle
            v-for="(item, index) in details.Solutions"
            :key="index"
          >
            <v-icon>mdi-circle-small</v-icon>{{ item.Name }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="details && 'TypeSolution' in details">
        <v-list-item-icon>
          <v-icon>mdi-information-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle> Tipo de solución </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ details.TypeSolution[0].Name }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="opportunity && 'Detail' in opportunity.Opportunitie">
        <v-list-item-icon>
          <v-icon>mdi-information-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle> Detalles de la solucion </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ opportunity.Opportunitie.Detail }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="details && 'CrossBusinessUnitites' in details">
        <v-list-item-icon>
          <v-icon>mdi-account-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle> Cross selling </v-list-item-subtitle>
          <v-list-item-subtitle
            v-for="(item, index) in details.CrossBusinessUnitites"
            :key="index"
          >
            <v-icon>mdi-circle-small</v-icon>{{ item.Name }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="details && 'CrossSeller' in details">
        <v-list-item-icon><v-icon>mdi-domain</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle> Áreas involucradas </v-list-item-subtitle>
          <v-list-item-subtitle
            v-for="(item, index) in details.CrossSeller"
            :key="index"
          >
            <v-icon>mdi-circle-small</v-icon>{{ item.Name }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="opportunity && 'HasRival' in opportunity.Opportunitie">
        <v-list-item-icon>
          <v-icon>mdi-alert-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle> Competencia </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ opportunity.Opportunitie.Rival }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="opportunity && 'Commentary' in opportunity.Opportunitie"
      >
        <v-list-item-icon
          ><v-icon>mdi-comment-outline</v-icon></v-list-item-icon
        >
        <v-list-item-content>
          <v-list-item-subtitle> Comentarios </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ opportunity.Opportunitie.Commentary }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <SetOpportunity
      :visible="dialogEditOpportunity"
      :opportunity="opportunity.Opportunitie"
      :details="details"
      @closeDialog="closeDialog"
      @savedOpportunity="updateOps"
    />

    <v-btn
      color="primary"
      fab
      fixed
      bottom
      right
      @click="dialogEditOpportunity = true"
    >
      <v-icon>mdi-pencil</v-icon>
    </v-btn>

    <!-- {{ opportunity }} -->
    <!-- {{ details }} -->
  </DialogFullScreen>
</template>

<script>
import { mapState } from 'vuex'
import DialogFullScreen from '@/components/DialogFullScreen.vue'
import SetOpportunity from '@/components/SetOpportunity.vue'

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    opportunity: {
      type: Object,
      required: true
    },
    details: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogEditOpportunity: false
    }
  },
  components: { DialogFullScreen, SetOpportunity },
  methods: {
    closeDialog() {
      this.dialogEditOpportunity = false
    },
    updateOps() {
      this.dialogEditOpportunity = false
      this.$emit('updateOpportunity')
    }
  },
  computed: {
    ...mapState('account', ['darkTheme'])
  }
}
</script>
