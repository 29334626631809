<template>
  <v-container
    v-if="opportunity && sectors && substages && stages && actualSubStage"
  >
    <v-row v-if="opportunity">
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    </v-row>
    <v-row class="mb-12">
      <v-col cols="12">
        <v-card class="my-card" v-scrollfadein>
          <v-card-text>
            <v-row>
              <v-col class="d-flex justify-space-between align-center">
                <div style="max-width: 80%">
                  <span
                    :class="
                      'subtitle-2 ' + (darkTheme ? 'text-white' : 'text-black')
                    "
                  >
                    {{ opportunity.Opportunitie.Name }}
                  </span>
                </div>
                <div>
                  <v-icon
                    v-if="opportunity.Opportunitie.Mustwin"
                    color="orange darken-4"
                  >
                    mdi-trophy
                  </v-icon>
                  <v-menu bottom left :offset-y="true">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list dense>
                      <v-list-item @click="dialogDetails = true">
                        <v-list-item-title> Ver detalles </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="dialogRate = true">
                        <v-list-item-title>
                          Ver calificación
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="dialogLost = true"
                        v-if="opportunity.Status.Label != 'Won'"
                      >
                        <v-list-item-title>
                          Marcar como perdida
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
            <v-icon class="mr-2">mdi-domain</v-icon>
            <span class="actName">{{ opportunity.Cliente.Name }}</span>
            <br />
            <span class="actInfo">{{ opportunity.CompanyType.Name }}</span>
            <br />
            <span class="actInfo">
              Valor: {{ opportunity.Opportunitie.Budget | currency }}
            </span>
            <br />
            <span class="actInfo">
              Fecha de cierre: {{ opportunity.Opportunitie.Deadline }}
            </span>
            <br />
            <span class="actInfo"> Sector: {{ sectors.Sectors.Name }} </span>
            <br />
            <span class="actInfo"> Subetapa: {{ actualSubStage.Name }} </span>
            <br />
            <v-progress-linear
              rounded
              v-model="actualAdvance"
              color="success"
              :height="15"
              class="mt-4"
            >
              <template>
                <small>
                  <strong>{{ opportunity.Stage.Name }}</strong>
                </small>
              </template>
            </v-progress-linear>
          </v-card-text>
          <v-card-actions v-if="nextSubStage">
            <v-spacer></v-spacer>
            <v-btn
              block
              color="primary"
              @click="showConfirmNextSubstageOpportunity = true"
            >
              <span style="white-space: normal">
                <v-icon>mdi-chevron-right</v-icon>{{ nextSubStage.Name }}
              </span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" v-scrollfadein>
        <OpportunityAccordion
          :activities="activities"
          :documents="documents"
          :opportunity="opportunity"
          @updateDocuments="updateDocuments"
          @editActivity="editActivity($event)"
        />
      </v-col>
    </v-row>

    <v-menu top right :offset-y="true">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" fab fixed bottom right v-bind="attrs" v-on="on">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item @click="dialogDocument = true">
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-file-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Documento </v-list-item-title>
        </v-list-item>
        <v-list-item @click="newActivity()">
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-clock-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Actividad </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <div>
      <ActivityEdit
        :visible="dialogActivity"
        :opportunity="opportunity"
        :activity="activityToEditComp"
        :activityTypes="activityTypes"
        @closeDialog="closeDialogs"
        @saveActivity="setActivityChanges($event)"
        v-if="activityToEdit"
        :key="'ae' + myKey"
      />

      <OpportunityDetails
        :visible="dialogDetails"
        :opportunity="opportunity"
        :details="details"
        @closeDialog="closeDialogs"
        @updateOpportunity="updateDetails"
        v-if="opportunity && details"
        :key="'od' + myKey"
      />

      <OpportunityRate
        :visible="dialogRate"
        :opportunity="opportunity"
        :details="details"
        @closeDialog="closeDialogs"
        @editRate="dialogSetRate = true"
        v-if="opportunity && details"
        :key="'or' + myKey"
      />

      <OpportunitySetRate
        :visible="dialogSetRate"
        :opportunity="opportunity"
        :compelling="compellingList"
        @closeDialog="closeDialogs"
        @saveOpportunity="setCalification($event)"
        v-if="opportunity && compellingList"
        :key="'osr' + myKey"
      />

      <LostDialog
        :visible="dialogLost"
        :opportunity="opportunity"
        :losts="lost"
        @cancelado="closeDialogs"
        @setLost="setLostOpportunity(true, $event)"
        v-if="opportunity && lost"
        :key="'ld' + myKey"
      />

      <NewDocument
        :visible="dialogDocument"
        :opportunity="opportunity"
        @cancelado="closeDialogs"
        @actualizado="updateDocuments"
        v-if="opportunity"
        :key="'nd' + myKey"
      />

      <ConfirmDialog
        :show="showConfirmUpdateOpportunity"
        question="¿Seguro que desea editar esta Oportunidad?"
        @cancelado="showConfirmUpdateOpportunity = false"
        @aceptado="saveOpportunity(true)"
      />

      <ConfirmDialog
        :show="showConfirmCalification"
        question="¿Seguro que desea editar esta Oportunidad?"
        @cancelado="showConfirmCalification = false"
        @aceptado="saveCalification(true, calification)"
      />

      <ConfirmDialog
        :show="showConfirmNextSubstageOpportunity"
        question="¿Seguro que desea avanzar de etapa esta Oportunidad?"
        @cancelado="showConfirmNextSubstageOpportunity = false"
        @aceptado="setNextSubStage(true, nextSubStage)"
      />

      <ConfirmDialog
        :show="showConfirmNewActivity"
        :question="
          'Id' in activityToEdit
            ? '¿Actualizar actividad?'
            : '¿Guardar nueva actividad?'
        "
        @cancelado="showConfirmNewActivity = false"
        @aceptado="saveActivity()"
        v-if="activityToEdit"
      />
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import OpportunityAccordion from '@/components/OpportunityAccordion.vue'
import OpportunityDetails from '@/components/OpportunityDetails.vue'
import OpportunityRate from '@/components/OpportunityRate.vue'
import OpportunitySetRate from '@/components/OpportunitySetRate.vue'
import ActivityEdit from '@/components/ActivityEdit.vue'
import LostDialog from '@/components/LostDialog.vue'
import NewDocument from '@/components/NewDocument.vue'

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    ConfirmDialog,
    OpportunityAccordion,
    ActivityEdit,
    OpportunityDetails,
    OpportunityRate,
    OpportunitySetRate,
    LostDialog,
    NewDocument
  },
  data() {
    return {
      myKey: 0,
      opportunity: null,
      activities: null,
      activityTypes: null,
      substages: null,
      substagesByType: null,
      rules: null,
      sectors: null,
      stages: null,
      details: null,
      lost: null,
      documents: null,
      compellingList: null,
      documentToDelete: 0,
      showConfirmUpdateOpportunity: false,
      showConfirmDeleteDocument: false,
      showConfirmNewActivity: false,
      showConfirmNextSubstageOpportunity: false,
      showConfirmCalification: false,
      showConfirmLost: false,
      dialogDetails: false,
      dialogRate: false,
      dialogSetRate: false,
      dialogActivity: false,
      dialogDocument: false,
      dialogLost: false,
      emptyActivity: {
        Name: '',
        Objective: '',
        Datetime: '',
        Participants: '',
        Notes: '',
        Reminder: '',
        Status: false,
        OpportunityId: parseInt(this.id),
        ActivityTypeId: 0,
        StageId: 0
      },
      activityToEdit: null,
      calification: null
    }
  },
  methods: {
    checkKey(key, object) {
      return key in object
    },
    closeDialogs() {
      this.dialogDetails = false
      this.dialogRate = false
      this.dialogSetRate = false
      this.dialogActivity = false
      this.dialogDocument = false
      this.dialogLost = false

      this.showConfirmUpdateOpportunity = false
      this.showConfirmDeleteDocument = false
      this.showConfirmNewActivity = false
      this.showConfirmNextSubstageOpportunity = false
      this.showConfirmCalification = false
      this.showConfirmLost = false

      this.activityToEdit = null

      this.myKey++
    },
    newActivity() {
      this.activityToEdit = this.emptyActivity
      this.activityToEdit.StageId = this.opportunity.Stage.Id
      this.dialogActivity = true
    },
    editActivity(activity) {
      this.activityToEdit = activity
      if (!('StageId' in this.activityToEdit)) {
        this.activityToEdit.StageId = this.opportunity.Stage.Id
      }
      this.dialogActivity = true
    },
    setActivityChanges(activity) {
      this.activityToEdit = activity
      this.showConfirmNewActivity = true
    },
    setCalification(opportunity) {
      this.calification = opportunity
      this.showConfirmCalification = true
    },
    getOpportunity(showOverlay) {
      let headers = {
        Opportunities_id: this.id
      }

      axios
        .get(
          this.$endpointsBaseUrl + 'Opportunities/Get_OpportunitiesDetails',
          {
            showOverlay,
            headers: headers
          }
        )
        .then((response) => {
          this.opportunity = response.data
          this.getSubstagesByType(false, this.opportunity.CompanyType.Id)
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    },
    getActivities(showOverlay) {
      let headers = {
        opportunitie_id: this.id
      }

      axios
        .get(
          this.$endpointsBaseUrl +
            'Activities/Get_JoinActivitiesByOpportunities',
          {
            showOverlay,
            headers: headers
          }
        )
        .then((response) => {
          this.activities = response.data
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    },
    getSubstages(showOverlay) {
      let headers = {
        opportunities_id: this.id
      }

      axios
        .get(this.$endpointsBaseUrl + 'SubStages/Get_SubStagesByOpportunity', {
          showOverlay,
          headers: headers
        })
        .then((response) => {
          this.substages = response.data
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    },
    getSubstagesByType(showOverlay, companyType) {
      let headers = {
        companitype: companyType
      }

      axios
        .get(this.$endpointsBaseUrl + 'SubStages/Get_SubstagesByType', {
          showOverlay,
          headers: headers
        })
        .then((response) => {
          this.substagesByType = response.data
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    },
    getRules(showOverlay) {
      let headers = {
        Opportunitie_id: this.id
      }

      axios
        .get(
          this.$endpointsBaseUrl + 'OpportunitiesHasRules/Get_RulesSubStage',
          {
            showOverlay,
            headers: headers
          }
        )
        .then((response) => {
          this.rules = response.data
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    },
    getSectors(showOverlay) {
      let headers = {
        opportunitie_id: this.id
      }

      axios
        .get(
          this.$endpointsBaseUrl +
            'OpportunitiesHasSectors/Get_OpportunitiesHasSectors',
          {
            showOverlay,
            headers: headers
          }
        )
        .then((response) => {
          this.sectors = response.data[0]
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    },
    getStages(showOverlay) {
      let headers = {
        id: 0
      }

      axios
        .get(this.$endpointsBaseUrl + 'Stages/Get_Stages', {
          showOverlay,
          headers: headers
        })
        .then((response) => {
          this.stages = response.data
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    },
    getDetails(showOverlay) {
      let headers = {
        opportunities_id: this.id
      }

      axios
        .get(
          this.$endpointsBaseUrl + 'Opportunities/Get_OpportunitiesDetailsList',
          {
            showOverlay,
            headers: headers
          }
        )
        .then((response) => {
          this.details = response.data
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    },
    getLost(showOverlay) {
      axios
        .get(this.$endpointsBaseUrl + 'Lost/Get_Lost', {
          showOverlay
        })
        .then((response) => {
          this.lost = response.data
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    },
    getDocuments(showOverlay) {
      let headers = {
        OpportunityID: this.id
      }

      axios
        .get(this.$endpointsBaseUrl + 'Documents/Get_Documents', {
          showOverlay,
          headers: headers
        })
        .then((response) => {
          this.documents = response.data
          for (let i = 0; i < this.documents.length; i++) {
            this.documents[i].loading = false
          }
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    },
    getActivityTypes(showOverlay) {
      let headers = {
        Id: 0
      }

      axios
        .get(this.$endpointsBaseUrl + 'ActivityType/Get_ActivityType', {
          showOverlay,
          headers: headers
        })
        .then((response) => {
          this.activityTypes = response.data
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    },
    getCompelling(showOverlay) {
      let headers = {
        Id: 0
      }

      axios
        .get(this.$endpointsBaseUrl + 'CompellingReason/Get_CompellingReason', {
          showOverlay,
          headers: headers
        })
        .then((response) => {
          this.compellingList = response.data
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    },
    updateDocuments() {
      this.closeDialogs()
      this.getDocuments(true)
    },
    saveOpportunity(showOverlay) {
      if (
        (this.opportunity.Opportunitie.Risks &&
          this.opportunity.Opportunitie.Risks.length > 0) ||
        (this.opportunity.Opportunitie.Dependencies &&
          this.opportunity.Opportunitie.Dependencies.length > 0)
      ) {
        axios
          .put(
            this.$endpointsBaseUrl + 'Opportunities/Update_Opportunity',
            this.opportunity.Opportunitie,
            {
              showOverlay
            }
          )
          .then(() => {
            this.$store.commit('showSnackBar', {
              text: 'Oportunidad actualizada',
              color: 'success',
              icon: 'mdi-check-bold'
            })
            this.getOpportunity(true)
            this.showConfirmUpdateOpportunity = false
          })
          .catch((error) => {
            console.log('Error: ', error)
            this.showConfirmUpdateOpportunity = false
          })
      } else {
        this.$store.commit('showSnackBar', {
          text: 'Al menos un campo debe tener información',
          color: 'warning',
          icon: 'mdi-alert'
        })
        this.showConfirmUpdateOpportunity = false
      }
    },
    saveCalification(showOverlay, opportunity) {
      this.showConfirmCalification = false
      axios
        .put(
          this.$endpointsBaseUrl + 'Opportunities/Update_Opportunity',
          opportunity,
          {
            showOverlay
          }
        )
        .then(() => {
          this.dialogSetRate = false
          this.getOpportunity(true)
          if (this.actualSubStage.Order == 1) {
            axios
              .put(
                this.$endpointsBaseUrl +
                  'Opportunities/Set_OpportunityNextStage',
                this.opportunity.Opportunitie,
                {
                  showOverlay
                }
              )
              .then((response) => {
                if (response.data == 'ok') {
                  this.getOpportunity(true)
                  this.$store.commit('showSnackBar', {
                    text: 'Oportunidad actualizada. ',
                    color: 'success',
                    icon: 'mdi-check-bold'
                  })
                } else {
                  this.$store.commit('showSnackBar', {
                    text: response.data + '.',
                    color: 'warning',
                    icon: 'mdi-alert'
                  })
                }
              })
              .catch((error) => {
                console.log(error)
              })
              .then(() => {
                this.closeDialogs()
              })
          }
        })
        .catch((error) => {
          console.log('Error: ', error)
          this.dialogSetRate = false
        })
    },
    setLostOpportunity(showOverlay, opportunity) {
      let Opportunitie = opportunity
      axios
        .put(
          this.$endpointsBaseUrl + 'Opportunities/Set_OpportunityLost',
          Opportunitie,
          {
            showOverlay
          }
        )
        .then(() => {
          this.$store.commit('showSnackBar', {
            text: 'Oportunidad actualizada',
            color: 'success',
            icon: 'mdi-check-bold'
          })
        })
        .catch((error) => {
          console.log(error)
        })
        .then(() => {
          this.getOpportunity(true)
          this.dialogLost = false
          this.$router.push('/myOpportunities')
        })
    },
    setNextSubStage(showOverlay, nextSubStage) {
      let subOrder = nextSubStage.Order
      let companyType = this.opportunity.CompanyType.Name
      let docMessage = ''
      if (
        (subOrder == 3 &&
          (companyType.includes('publico') ||
            companyType.includes('publico'))) ||
        (subOrder == 4 &&
          (companyType.includes('privada') || companyType.includes('privado')))
      ) {
        docMessage = 'Recuerde que debe subir sus documentos a la plataforma.'
      }
      if (subOrder == 2) {
        this.dialogSetRate = true
        return
      } else if (
        (!('PercentRisk' in this.opportunity.Opportunitie) &&
          !('PercentRisk' in this.opportunity.Opportunitie)) ||
        (this.opportunity.Opportunitie.PercentRisk == 0 &&
          this.opportunity.Opportunitie.PercentMargin == 0)
      ) {
        this.$store.commit('showSnackBar', {
          text: 'Debes calificar la oportunidad. ' + docMessage,
          color: 'warning',
          icon: 'mdi-alert'
        })
        return
      } else {
        axios
          .put(
            this.$endpointsBaseUrl + 'Opportunities/Set_OpportunityNextStage',
            this.opportunity.Opportunitie,
            {
              showOverlay
            }
          )
          .then((response) => {
            if (response.data == 'ok') {
              this.getOpportunity(true)
              this.$store.commit('showSnackBar', {
                text: 'Oportunidad actualizada. ' + docMessage,
                color: 'success',
                icon: 'mdi-check-bold'
              })
            } else {
              this.$store.commit('showSnackBar', {
                text: response.data + '. ' + docMessage,
                color: 'warning',
                icon: 'mdi-alert'
              })
            }
          })
          .catch((error) => {
            console.log(error)
          })
          .then(() => {
            this.showConfirmNextSubstageOpportunity = false
          })
      }
      console.log(docMessage)
    },
    saveActivity(showOverlay) {
      if (this.activityToEdit != null) {
        if ('Id' in this.activityToEdit) {
          axios
            .put(
              this.$endpointsBaseUrl + 'Activities/Update_Activities',
              this.activityToEdit,
              {
                showOverlay
              }
            )
            .then(() => {
              this.$store.commit('showSnackBar', {
                text: 'Actividad actualizada',
                color: 'success',
                icon: 'mdi-check-bold'
              })
              this.getOpportunity(true)
            })
            .catch(() => {
              this.showConfirmNewActivity = false
            })
            .then(() => {
              this.showConfirmNewActivity = false
              this.closeDialogs()
              this.getActivities(true)
            })
        } else {
          axios
            .post(
              this.$endpointsBaseUrl + 'Activities/Create_Activities',
              this.activityToEdit,
              {
                showOverlay
              }
            )
            .then(() => {
              this.$store.commit('showSnackBar', {
                text: 'Actividad creada',
                color: 'success',
                icon: 'mdi-check-bold'
              })
              this.getOpportunity(true)
            })
            .catch(() => {
              this.showConfirmNewActivity = false
            })
            .then(() => {
              this.showConfirmNewActivity = false
              this.closeDialogs()
              this.getActivities(true)
            })
        }
      } else {
        this.$store.commit('showSnackBar', {
          text: 'No hay información sobre la atividad a guardar',
          color: 'warning',
          icon: 'mdi-alert'
        })
        this.showConfirmNewActivity = false
      }
    },
    updateDetails() {
      this.dialogDetails = false
      this.getOpportunity(false)
      this.getActivities(false)
      this.getActivityTypes(false)
      this.getSubstages(false)
      this.getDocuments(false)
      this.getRules(false)
      this.getSectors(false)
      this.getStages(false)
      this.getDetails(false)
      this.getLost(false)
      this.getCompelling(false)
    }
  },
  computed: {
    ...mapState('account', ['darkTheme']),
    actualAdvance() {
      return (this.opportunity.Stage.Orden * 100) / this.stages.length
    },
    activityToEditComp() {
      return this.activityToEdit
    },
    actualSubStage() {
      if (this.substagesByType) {
        let substage = this.substagesByType.filter(
          (substg) => substg.Id == this.opportunity.Opportunitie.SubStageId
        )
        return substage[0]
      } else {
        return null
      }
    },
    nextSubStage() {
      if (this.substagesByType && this.actualSubStage) {
        let nextStg = this.substagesByType.filter(
          (substg) => substg.Order == this.actualSubStage.Order + 1
        )
        return nextStg[0]
      } else {
        return null
      }
    },
    breadcrumbs() {
      return [
        {
          text: 'Mis oportunidades',
          disabled: false,
          href: '/myOpportunities'
        },
        {
          text: this.opportunity.Opportunitie.Name,
          disabled: true,
          href: '#'
        }
      ]
    }
  },
  mounted() {
    this.getOpportunity(false)
    this.getActivities(false)
    this.getActivityTypes(false)
    this.getSubstages(false)
    this.getDocuments(false)
    this.getRules(false)
    this.getSectors(false)
    this.getStages(false)
    this.getDetails(false)
    this.getLost(false)
    this.getCompelling(false)
  }
}
</script>
