<template>
  <DialogFullScreen
    title="Calificación"
    :visible="visible"
    @closeDialog="$emit('closeDialog')"
  >
    <v-list :class="darkTheme ? 'hasSeparatorsD' : 'hasSeparators'">
      <v-list-item
        three-line
        v-if="opportunity && 'CompellingReason' in opportunity"
      >
        <v-list-item-icon
          ><v-icon>mdi-information-outline</v-icon></v-list-item-icon
        >
        <v-list-item-content>
          <v-list-item-subtitle> Compellig reason to act </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ opportunity.CompellingReason.Name }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="opportunity && 'Budget' in opportunity.Opportunitie">
        <v-list-item-icon><v-icon>mdi-currency-usd</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle> Presupuesto </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ opportunity.Opportunitie.Budget | currency }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="opportunity && 'Deadline' in opportunity.Opportunitie">
        <v-list-item-icon
          ><v-icon>mdi-calendar-outline</v-icon></v-list-item-icon
        >
        <v-list-item-content>
          <v-list-item-subtitle> Fecha de cierre </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ opportunity.Opportunitie.Deadline }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="opportunity && 'TakeDecision' in opportunity.Opportunitie"
      >
        <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle>
            ¿Tiene acceso a tomador de decisión?
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ opportunity.Opportunitie.TakeDecision }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="opportunity && 'HasRival' in opportunity.Opportunitie">
        <v-list-item-icon>
          <v-icon>mdi-alert-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle> ¿Tenemos competencia? </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ opportunity.Opportunitie.Rival }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="opportunity && 'PercentMargin' in opportunity.Opportunitie"
      >
        <v-list-item-icon><v-icon>mdi-percent</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle> Margen </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ (opportunity.Opportunitie.PercentMargin / 100) | percent }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="opportunity && 'PercentRisk' in opportunity.Opportunitie"
      >
        <v-list-item-icon><v-icon>mdi-percent</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle> Riesgo </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ (opportunity.Opportunitie.PercentRisk / 100) | percent }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-btn color="primary" fab fixed bottom right @click="$emit('editRate')">
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
  </DialogFullScreen>
</template>

<script>
import { mapState } from 'vuex'
import DialogFullScreen from '@/components/DialogFullScreen.vue'

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    opportunity: {
      type: Object,
      required: true
    }
  },
  components: { DialogFullScreen },
  computed: {
    ...mapState('account', ['darkTheme'])
  }
}
</script>
