var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('DialogFullScreen',{attrs:{"title":"Registro de actividad","visible":_vm.visible},on:{"closeDialog":function($event){return _vm.$emit('closeDialog')}}},[(_vm.activity)?_c('v-container',[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{class:'subtitle-2 ' + (_vm.darkTheme ? 'text-white' : 'text-black')},[_vm._v(" "+_vm._s(_vm.opportunity.Opportunitie.Name)+" ")]),_c('span',{class:'subtitle-1 ' + (_vm.darkTheme ? 'text-white' : 'text-black')},[_vm._v(_vm._s(_vm.opportunity.Cliente.Name))])])],1),_c('v-row',[(_vm.activityTypes)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{ref:"activityType",attrs:{"items":_vm.activityTypes,"prepend-icon":"mdi-timelapse","label":"Selecciona un tipo de actividad","item-text":"Name","item-value":"Id","rules":[
              function () { return !!_vm.activity.ActivityTypeId || 'Este campo es requerido'; }
            ]},model:{value:(_vm.activity.ActivityTypeId),callback:function ($$v) {_vm.$set(_vm.activity, "ActivityTypeId", $$v)},expression:"activity.ActivityTypeId"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"objective",attrs:{"prepend-icon":"mdi-domain","label":"Objetivo","rules":[function () { return !!_vm.activity.Objective || 'Este campo es requerido'; }]},model:{value:(_vm.activity.Objective),callback:function ($$v) {_vm.$set(_vm.activity, "Objective", $$v)},expression:"activity.Objective"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","nudge-bottom":"10","offset-y":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({ref:"dateActivity",attrs:{"label":"Fecha","prepend-icon":"mdi-calendar","readonly":"","rules":[function () { return !!_vm.dateActivity || 'Este campo es requerido'; }]},model:{value:(_vm.dateActivity),callback:function ($$v) {_vm.dateActivity=$$v},expression:"dateActivity"}},'v-text-field',attrs,false),on))]}}],null,false,610593705),model:{value:(_vm.menuDateActivity),callback:function ($$v) {_vm.menuDateActivity=$$v},expression:"menuDateActivity"}},[_c('v-date-picker',{attrs:{"bottom":"","full-width":""},on:{"input":function($event){_vm.menuDateActivity = false}},model:{value:(_vm.dateActivity),callback:function ($$v) {_vm.dateActivity=$$v},expression:"dateActivity"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.timeActivity,"transition":"scale-transition","nudge-bottom":"10","offset-y":""},on:{"update:returnValue":function($event){_vm.timeActivity=$event},"update:return-value":function($event){_vm.timeActivity=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({ref:"timeActivity",attrs:{"label":"Hora","prepend-icon":"mdi-clock-outline","readonly":"","rules":[function () { return !!_vm.timeActivity || 'Este campo es requerido'; }]},model:{value:(_vm.timeActivity),callback:function ($$v) {_vm.timeActivity=$$v},expression:"timeActivity"}},'v-text-field',attrs,false),on))]}}],null,false,1737627266),model:{value:(_vm.menuTimeActivity),callback:function ($$v) {_vm.menuTimeActivity=$$v},expression:"menuTimeActivity"}},[(_vm.menuTimeActivity)?_c('v-time-picker',{attrs:{"ampm-in-title":"","format":"ampm","full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.timeActivity)}},model:{value:(_vm.timeActivity),callback:function ($$v) {_vm.timeActivity=$$v},expression:"timeActivity"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"participants",attrs:{"prepend-icon":"mdi-account-multiple","label":"Participantes"},model:{value:(_vm.activity.Participants),callback:function ($$v) {_vm.$set(_vm.activity, "Participants", $$v)},expression:"activity.Participants"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{ref:"notes",attrs:{"prepend-icon":"mdi-comment","label":"Nota","rows":"1","auto-grow":""},model:{value:(_vm.activity.Notes),callback:function ($$v) {_vm.$set(_vm.activity, "Notes", $$v)},expression:"activity.Notes"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{attrs:{"label":"Actividad realizada"},model:{value:(_vm.activity.Status),callback:function ($$v) {_vm.$set(_vm.activity, "Status", $$v)},expression:"activity.Status"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"block":"","color":"primary"},on:{"click":_vm.sendSaveActivity}},[_vm._v(" Guardar ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"block":"","color":"secondary"},on:{"click":function($event){return _vm.$emit('closeDialog')}}},[_vm._v(" Cancelar ")])],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }