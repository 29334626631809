<template>
  <v-dialog persistent v-model="visible">
    <v-card class="my-card pb-2">
      <v-card-text class="pb-0 mb-0">
        <v-row class="mt-4">
          <v-col cols="12">
            <span
              :class="
                'subtitle-1 font-weight-bold ' +
                (darkTheme ? 'text-white' : 'text-black')
              "
            >
              Seleccione motivo de perdida
            </span>
          </v-col>
          <v-col cols="12" v-if="opportunity">
            <v-select
              v-model="opportunity.Opportunitie.LostId"
              :items="losts"
              label="Selecciona un tipo de actividad"
              item-text="Name"
              item-value="Id"
              ref="lost"
              :rules="[
                () =>
                  !!opportunity.Opportunitie.LostId || 'Este campo es requerido'
              ]"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-list-item>
          <v-btn color="primary" @click="setLost"> Aceptar </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="$emit('cancelado')">
            Cancelar
          </v-btn>
        </v-list-item>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    opportunity: {
      type: Object,
      required: true
    },
    losts: {
      type: Array,
      required: true
    }
  },
  methods: {
    setLost() {
      this.formHasErrors = false

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true
        this.$refs[f].validate(true)
      })

      if (!this.formHasErrors) {
        let date = moment().format('YYYY-MM-DD')
        this.opportunity.Opportunitie.Deadline = date
        this.$emit('setLost', this.opportunity.Opportunitie)
      }
    }
  },
  computed: {
    ...mapState('account', ['darkTheme']),
    form() {
      return {
        lost: this.opportunity.Opportunitie.LostId
      }
    }
  }
}
</script>
