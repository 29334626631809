<template>
  <div>
    <v-dialog persistent v-model="visible">
      <v-card class="my-card pb-2">
        <v-card-text class="pb-0 mb-0">
          <v-row class="mt-4">
            <v-col cols="12">
              <span
                :class="
                  'subtitle-1 font-weight-bold ' +
                  (darkTheme ? 'text-white' : 'text-black')
                "
              >
                Seleccione un documento
              </span>
            </v-col>
            <v-col cols="12">
              <v-file-input
                show-size
                clearable
                truncate-length="19"
                v-model="fileToUpload"
                ref="myFile"
                :rules="[() => !!fileToUpload || 'Este campo es requerido']"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-list-item>
            <v-btn
              color="primary"
              :loading="fileLoading"
              @click="uploadFile(true)"
            >
              Aceptar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="secondary" @click="$emit('cancelado')">
              Cancelar
            </v-btn>
          </v-list-item>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    opportunity: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      fileToUpload: null,
      fileLoading: false,
      formHasErrors: false
    }
  },
  methods: {
    uploadFile(showOverlay) {
      this.formHasErrors = false

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true
        this.$refs[f].validate(true)
      })

      if (!this.formHasErrors) {
        this.fileLoading = true
        let extension = this.fileToUpload.name.split('.')
        extension = extension[extension.length - 1]
        let fileObject = {
          Name: this.fileToUpload.name,
          Type: extension,
          Length: this.fileToUpload.size,
          StringFile: '',
          OpportunityId: this.opportunity.Opportunitie.Id
        }

        console.log(typeof this.fileToUpload)

        var reader = new FileReader()
        reader.readAsDataURL(this.fileToUpload)
        reader.onload = () => {
          let fileUrl = reader.result.split(',')
          fileUrl = fileUrl[fileUrl.length - 1]
          // fileObject.StringFile = reader.result
          fileObject.File = fileUrl
          console.log(fileUrl)
          // fileObject.File =

          axios
            .post(
              this.$endpointsBaseUrl + 'Documents/Create_Document',
              fileObject,
              {
                showOverlay
              }
            )
            .then(() => {
              this.$store.commit('showSnackBar', {
                text: 'Documento agregado',
                color: 'success',
                icon: 'mdi-check-bold'
              })
            })
            .catch(() => {
              this.$emit('cancelado')
            })
            .then(() => {
              this.fileLoading = false
              this.$emit('actualizado')
            })
        }
      }
    }
  },
  computed: {
    ...mapState('account', ['darkTheme']),
    form() {
      return {
        myFile: this.fileToUpload
      }
    }
  }
}
</script>
