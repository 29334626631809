<template>
  <v-dialog persistent v-model="show">
    <v-card class="my-card pb-2">
      <v-card-text class="pb-0 mb-0">
        <v-row class="mt-4">
          <v-col cols="12">
            <span
              :class="
                'subtitle-1 font-weight-bold ' +
                (darkTheme ? 'text-white' : 'text-black')
              "
            >
              {{ question }}
            </span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-list-item>
          <v-btn color="primary" @click="$emit('aceptado')"> Aceptar </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="$emit('cancelado')">
            Cancelar
          </v-btn>
        </v-list-item>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    question: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState('account', ['darkTheme'])
  }
}
</script>
