<template>
  <DialogFullScreen
    title="Calificar oportunidad"
    :visible="visible"
    @closeDialog="$emit('closeDialog')"
  >
    <v-container v-if="opportunity">
      <v-row class="mt-4">
        <v-col cols="12" v-if="compelling && compelling.length > 0">
          <v-select
            v-model="opportunity.Opportunitie.CompellingReasonId"
            :items="compelling"
            prepend-icon="mdi-timelapse"
            label="Compelling reason to act"
            item-text="Name"
            item-value="Id"
            ref="compelling"
            :rules="[
              () =>
                !!opportunity.Opportunitie.CompellingReasonId ||
                'Este campo es requerido'
            ]"
          ></v-select>
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="opportunity.Opportunitie.Budget"
            prepend-icon="mdi-currency-usd"
            prefix="$"
            suffix="MXN"
            type="number"
            min="0"
            label="Presupuesto"
            ref="presupuesto"
            :rules="[
              () =>
                !!opportunity.Opportunitie.Budget || 'Este campo es requerido'
            ]"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-menu
            transition="scale-transition"
            nudge-bottom="10"
            offset-y
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="opportunity.Opportunitie.Deadline"
                label="Fecha"
                prepend-icon="mdi-calendar"
                readonly
                ref="deadline"
                :rules="[
                  () =>
                    !!opportunity.Opportunitie.Deadline ||
                    'Este campo es requerido'
                ]"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              bottom
              v-model="opportunity.Opportunitie.Deadline"
              full-width
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12">
          <v-switch
            v-model="opportunity.Opportunitie.HasTakedecision"
            label="¿Tienes acceso a tomador de decisión?"
          ></v-switch>
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="opportunity.Opportunitie.TakeDecision"
            prepend-icon="mdi-account"
            label="¿Quién?"
            ref="tomadordesicion"
            :disabled="!opportunity.Opportunitie.HasTakedecision"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-switch
            v-model="opportunity.Opportunitie.HasRival"
            label="¿Tenemos competencia?"
          ></v-switch>
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="opportunity.Opportunitie.Rival"
            prepend-icon="mdi-account-multiple"
            label="¿Quién?"
            ref="competencia"
            :disabled="!opportunity.Opportunitie.HasRival"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="opportunity.Opportunitie.PercentMargin"
            prepend-icon="mdi-percent"
            suffix="%"
            type="number"
            min="0"
            max="100"
            label="Margen"
            ref="margen"
            :rules="[
              () =>
                !!(
                  opportunity.Opportunitie.PercentMargin > 0 &&
                  opportunity.Opportunitie.PercentMargin <= 100
                ) || 'El valor de este campo debe ser entre 1 y 100'
            ]"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="opportunity.Opportunitie.PercentRisk"
            prepend-icon="mdi-percent"
            suffix="%"
            type="number"
            min="0"
            max="100"
            label="Riesgo"
            ref="riesgo"
            :rules="[
              () =>
                !!(
                  opportunity.Opportunitie.PercentRisk >= 0 &&
                  opportunity.Opportunitie.PercentRisk <= 100
                ) || 'El valor de este campo debe ser entre 1 y 100'
            ]"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-btn block color="primary" @click="saveOpportunity">
            Guardar
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block color="secondary" @click="$emit('closeDialog')">
            Cancelar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </DialogFullScreen>
</template>

<script>
import { mapState } from 'vuex'
import DialogFullScreen from '@/components/DialogFullScreen.vue'

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    opportunity: {
      type: Object,
      required: true
    },
    compelling: {
      type: Array,
      required: true
    }
  },
  components: { DialogFullScreen },
  data() {
    return {
      menuDateActivity: false
    }
  },
  methods: {
    saveOpportunity() {
      this.opportunity.Opportunitie.Budget = parseFloat(
        this.opportunity.Opportunitie.Budget
      )
      this.opportunity.Opportunitie.PercentMargin = parseInt(
        this.opportunity.Opportunitie.PercentMargin
      )
      this.opportunity.Opportunitie.PercentRisk = parseInt(
        this.opportunity.Opportunitie.PercentRisk
      )
      this.formHasErrors = false

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true
        this.$refs[f].validate(true)
      })

      if (!this.formHasErrors) {
        this.$emit('saveOpportunity', this.opportunity.Opportunitie)
      }
    }
  },
  computed: {
    ...mapState('account', ['darkTheme']),
    form() {
      return {
        compelling: this.opportunity.Opportunitie.CompellingReasonId,
        presupuesto: this.opportunity.Opportunitie.Budget,
        deadline: this.opportunity.Opportunitie.Deadline,
        margen: this.opportunity.Opportunitie.PercentMargin,
        riesgo: this.opportunity.Opportunitie.PercentRisk
      }
    }
  }
}
</script>
